import React from "react"
import {
  Box,
  Grid,
  Heading,
  Paragraph,
  Text,
  ResponsiveContext,
} from "grommet"

import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/seo"
import Layout from "../components/layout"

export default function About({ data }) {
  const columns = {
    small: ["flex"],
    medium: ["flex", "flex"],
    large: ["flex", "flex"],
    xlarge: ["flex", "flex"],
  }

  return (
    <Layout>
      <Seo title="About" />
      <ResponsiveContext.Consumer>
        {size => {
          let columnsVal = columns
          if (columns) {
            if (columns[size]) {
              columnsVal = columns[size]
            }
          }
          return (
            <Box>
              <Box width="xlarge" alignSelf="center" align="start" pad={{horizontal: "medium"}}>
                <Heading
                  margin={{ top: "xlarge", bottom: "large" }}
                  level="1"
                  size="medium"
                >
                  Our purpose
                </Heading>
                <Grid columns={!columnsVal ? size : columnsVal} gap="xlarge">
                  <Box>
                    <Paragraph fill margin={{ top: "none" }} size="large">
                      If you want to know how Po3 came to be, take a look at our
                      short brand origin video to hear about the thinking behind
                      the name and the principles that underpin what we do.
                    </Paragraph>
                    <Paragraph fill size="large">
                      We believe that by re-designing business we can rebuild
                      the economy to create a better more sustainable future for
                      all. Take a look at{" "}
                      <Link to="/manifesto">our manifesto</Link> for a deep dive
                      into our thinking.
                    </Paragraph>
                    <Paragraph fill size="large">
                      Po3 is getting B-corp certified.{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://bcorporation.net/about-b-corps"
                      >
                        What is B-corp?
                      </a>{" "}
                      It’s a global community of like minded people building a
                      better world for all through business. Goes without
                      saying, we like these people.
                    </Paragraph>
                  </Box>
                  <Box>
                    <iframe
                      title="origin video"
                      style={{
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "350px",
                      }}
                      src="https://www.youtube-nocookie.com/embed/OGolt7HcBG4"
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                    ></iframe>{" "}
                  </Box>
                </Grid>
              </Box>

              <Box
                background={{
                  image: `url(${data.workshopping.childImageSharp.fluid.src})`,
                  dark: false,
                  size: "cover",
                  position: "center",
                  repeat: "no-repeat",
                }}
                height="auto"
                margin={{ top: "xlarge" }}
                justify="center"
              >
                <Box
                  width="xlarge"
                  alignSelf="center"
                  pad={{ vertical: "medium", horizontal: "medium" }}
                >
                  <Box
                    margin={{ vertical: "xlarge" }}
                    alignSelf="start"
                    width={size === "small" ? "100%" : "50%"}
                    background="white"
                    pad={{
                      top: "large",
                      horizontal: "medium",
                      bottom: "small",
                    }}
                  >
                    <Heading
                      margin={{ vertical: "none" }}
                      level="3"
                      size="medium"
                    >
                      We don’t know all the answers, no-one does.
                    </Heading>
                    <Paragraph fill>
                      But we know how to ensure the best possible chance of
                      success by asking the right questions and bringing the
                      right people to the table from our global community of
                      design experts. We don’t have people ‘on the bench’ - we
                      only work with the best in industry on the most
                      interesting and complex problems to solve. And they’re not
                      just sitting around, they’re doing.
                    </Paragraph>
                  </Box>
                </Box>
              </Box>
              <Box width="xlarge" alignSelf="center" align="start">
                <Box
                  width="xlarge"
                  alignSelf="center"
                  pad={{ vertical: "medium", horizontal: "medium" }}
                >
                  <Grid
                    columns={!columnsVal ? size : columnsVal}
                    margin={{ vertical: "xlarge" }}
                    gap={size === "small" ? "medium" : "xlarge"}
                  >
                    <Box>
                      <Img fluid={data.kieran.childImageSharp.fluid} />
                    </Box>
                    <Box>
                      <Text>Co-founder</Text>
                      <Heading size="medium" margin={{ top: "none" }} level="3">
                        Kieran Mcbride
                      </Heading>
                      <Paragraph margin={{ top: "none" }} size="large">
                        I’ve been developing user-centred strategies since the
                        noughties, collaborating with progressive leadership
                        teams to define the futures of FTSE 100 businesses, the
                        public sector and charity organisations. I design ways
                        to shape organisational culture, enable behaviour change
                        and rapidly deliver new propositions. Also write a
                        little bit on Linkedin and previously for The Guardian,
                        Econsultancy and Internet Retailing.
                      </Paragraph>
                    </Box>
                  </Grid>
                  <Box
                    fill
                    direction={size === "small" ? "column-reverse" : "row"}
                    margin={{ bottom: "xlarge" }}
                    gap={size === "small" ? "medium" : "xlarge"}
                  >
                    <Box basis="1/2">
                      <Text>Co-founder</Text>
                      <Heading size="medium" level="3" margin={{ top: "none" }}>
                        Phil Sherwin-Nicholls
                      </Heading>
                      <Paragraph margin={{ top: "none" }} size="large">
                        Working at the intersection of technology, data and
                        design I help organisations solve wicked, population
                        scale problems. A service design specialist, I'm a
                        facilitator co-creating with subject matter experts and
                        end users ensuring we have the right perspectives in the
                        room to give us the best chance of success.
                      </Paragraph>
                    </Box>
                    <Box basis="1/2">
                      <Img fluid={data.phil.childImageSharp.fluid} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}
export const query = graphql`
  query AboutQuery {
    kieran: file(relativePath: { eq: "static/images/kieran.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    phil: file(relativePath: { eq: "static/images/phil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    workshopping: file(relativePath: { eq: "static/images/workshop1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          src
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
